/* =======================
Wavify Area 
==============================*/

.wavify-wrapper {
  position: relative;
  margin-bottom: -10px;

  .wavify-item {
    height: 150px;

    svg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}




