/*==========================
Featured Projects
===========================*/
.featured-projects {
      margin-top: 200px;
      @media #{$md-layout} {
        margin-top: 100px;
      }
      h2 {  
        color: #fff;
        margin-bottom: 0px;
      }
      .swiper-container {
        margin: 0 75px;
        @media #{$md-layout} {
          margin: 0;
        }
        .swiper-slide:nth-child() {
          visibility: hidden;
          @media #{$md-layout} {
            visibility: visible;
          }
        }
        .swiper-wrapper {
          max-width: 1400px;
          @media #{$md-layout} {
            max-width: 100%;;
          }
        }
      }

      .project-slide {
          margin: 0 35px;
          width: auto !important;
          box-sizing: border-box;
            img{
                width: auto;
                height: 40vh;
                @media #{$md-layout} {
                  width: 100%;
                  height: auto;
                  margin: 0;
                }
            }

          p {
            color: #fff;
            margin-bottom: 0px;
            text-transform: lowercase;
            &.project-title {
              margin-top: 25px;
              text-transform: uppercase;
              font-weight: bold;
            }
          }
          &:hover {
            p {
              color: $color-2;
              -webkit-transition: .5s;
              transition: .5s;
            }
          }
          @media #{$md-layout} {
            margin: 0;
            max-width: 100%;
          }
      }

      .next-btn {
        position: absolute; 
        top: 45%;
        right: 30px;
        @media #{$md-layout} {
          top: 100%;
        }
          span {
            color: #919191;
            transition: .3s;
            cursor: pointer;
            &.btn-arrow {
              display: inline-block;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                right: 0px;
                width: 25px;
                height: 25px;
                border: 2px solid currentColor;
                border-left: 0;
                border-bottom: 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
              }
            }
          }
          &:hover {
            span {
              color: #FFF;
            }
          }
      }

      .prev-btn {
        position: absolute; 
        top: 45%;
        left: 50px;
        @media #{$md-layout} {
          top: 100%;
        }
          span {
            color: #919191;
            transition: .3s;
            cursor: pointer;
            &.btn-arrow {
              display: inline-block;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                right: 0px;
                width: 25px;
                height: 25px;
                border: 2px solid currentColor;
                border-right: 0;
                border-top: 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
              }
            }
          }
          &:hover {
            span {
              color: #FFF;
            }
          }

      }
    
      

      .see-more-btn {
        width: 300px;
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;
        div {
          padding: 13px 40px;
          height: 55px;
          border-width: 2px;
          border-style: solid;
          cursor: pointer;
          font-weight: 600;
          color: #fff;
          border-color: #fff;
          -webkit-transition: all .3s;
          transition: all .3s;
          &:hover {
            color: #000;
            background-color: #fff;
          }
        }
        @media #{$sm-layout} {
          width: 100%;
        }
      } 
      

}



