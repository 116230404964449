/* =====================
    Team Page 
========================*/
.text-about{
  color: $white;
  position: relative;
  width: 100%;
  .info{
    position: absolute;
    max-width: 400px;
    top:0;
    right:0;
    @media #{$lg-layout} {
      position: relative;
    }
  }
}



.ale_mare{
  min-width: 350px;
  margin: 200px 0 0 0;
  .thumb {
    border-radius: 50% 50% 50% 0%;
  }
  .overlay{
    background-color: $color-1;
    transform: translate(2vh, -2vh);
  }
  @media #{$lg-layout} {
    margin: 25px 0;
  }
}
.ale_mica{
  min-width: 350px;
  margin: 0 150px 0 -150px;
  .thumb {
    border-radius: 50%;
  }
  .overlay{
    background-color: $color-3;
    transform: translate(2vh, 0px);
  }
  @media #{$lg-layout} {
    margin: 25px 0;
  }
}
.andre{
  min-width: 350px;
  margin: 200px 300px 0 -300px;
  .thumb {
    border-radius: 50% 50% 0% 0%;
  }
  .overlay{
    background-color: $color-2;
    transform: translate(2vh, -2vh);
    .info {
      color: $white;
    }
  }
  @media #{$lg-layout} {
    margin: 25px 0;
  }
}



.team {
    .thumb {
      position: relative;
      border: solid $black 2px;
      &::before{
        content: url(../../img/shapes/hand_1.svg);
        position: absolute;
        display: none;
        @media #{$lg-layout} {
          display: block;
        }
      }

      .member-img {
        width: 100%;
        border-radius: inherit;
      }
      .overlay {
        border-radius: inherit;
        opacity: 0;
        visibility: hidden;
        z-index: 100;
        -webkit-transition: .3s;
        transition: .3s;
      }

    }

      .info {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 0 50px;
        img{
          width: 30px;
          margin: 10px 30px;
        }
        h4{
          margin: 0;
        }
        p{
          font-size: 16px;
        }
        @media #{$lg-layout} {
          padding: 0 40px;
        }
      }

    &:hover {
      .thumb {
        &::before{
          @media #{$lg-layout} {
            display: none;
          }
        }
        .overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }

}


