
.play-list{
  margin: auto;
  text-align: right;
  .your-rythm {
    color: $white;
    @media #{$lg-layout} {
      display: none;
    }
  }
  .song {
    margin-top: 10px;
    transition: .3s;
    cursor: pointer;
    p {
      display: inline-block;
      background-color: $white;
      padding: 5px 10px;
      border-radius: 25px;
      transition: .3s;
    }
    :hover {
      background-color: $color-1;
    }
    &.selected {
      p {
        background-color: $color-1;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        transform: translateY(-50px);
        width: 50px;
        height: 50px;
        background: url("../../img/shapes/hand_1.svg") no-repeat;
        @media #{$lg-layout} {
          display: none;
        }
      }
    }
    @media #{$lg-layout} {
      font-size: 16px;
      display: inline-block;
      margin-right: 12px;
    }
  }
  @media #{$lg-layout} {
    text-align: left;
  }
}



.song-cover {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  .img-cover-default{
    height: 50vh;
    object-fit: contain;

  }
  .img-cover {
    height: 50vh;
    object-fit: cover;

  }
}


.play-btn{
  position: fixed;  
  right: 0;
  bottom: 0;
  z-index: 9999;

  // padding: 5px;
  // background: url("../../img/shapes/play_btn.svg") no-repeat;
  .play {
    margin: 20px;
    padding: 20px 0;
    width: 80px;
    height: 80px;
    border: solid 2px $black;
    border-radius: 50%;
    transition: 0.2s;
    cursor: pointer;
    color: $white;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    &.red-bg {
      background-color: $color-1;
    }
    &.blue-bg {
      background-color: $color-2;
    }
  }
}






