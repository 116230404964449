/* -------------------------
    Copyright Variation 
----------------------------*/
.footer{
  .logo{
    display: block;
    margin: auto auto 50px auto;
    width: 282px;
    img {
      width: 200px;
    }
    @media #{$md-layout} {
      display: none;
    }
  }
}

.iceday {
    display: block;
    margin-left: auto;
    margin-right: auto;
  .ice-bg{
    min-width: 305px;
  }
  .ice-gif{
    width: 208px;
    position: absolute;
    margin: auto;
    transform: translate(25%, 25%);
  }
}

.footer-menu {
  display: block;
  margin: auto auto 50px auto;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-right:20px;
      &:last-child{
        padding-right: 0;
      }
    a {
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      font-size: 16px;
      font-weight: $font-weight-medium;
      color: #000;
      }
    }
    @media #{$md-layout} {
      margin-top: 20px;
    }
  }
}












