#contact {
  background-color: $color-1;
 .form-style {
    .input-box {
      position: relative;
      margin-bottom: 50px;
      z-index: 1;

      input {
        border: 0;
        border-bottom: 2px solid #000;
        background: none;
        height: 60px;
        -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transition: all .3s cubic-bezier(1, 0.03, 0, 0.99);

        &::placeholder {
          color: $placeholder-color;
          opacity: 1;
        }
        &:-webkit-input-placeholder {
          color: $placeholder-color;
        }
      }

      &::after {
        content: "";
        width: 0;
        height: 2px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        background-color: #fff;
        -webkit-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
        transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
      }

      &:hover {
        &::after {
          width: 100%;
          left: 0;
          right: auto;
        }
      }
    
    }

    
    .form-action {

      @media #{$sm-layout} {
        display: block;
      }

      .form-feedback {
        position: absolute;
        margin-top: 15px;
        color: #000;
        font-weight: 600;
        font-size: 16px;
        @media #{$sm-layout} {
          margin: 20px 0 0 0;
        }
      }


      .form-submit {
        text-align: center;

        div {
          padding: 13px 40px;
          height: 55px;
          border-width: 2px;
          border-style: solid;
          cursor: pointer;
          font-weight: 600;
          color: #000;
          border-color: #000;
          -webkit-transition: all .3s;
          transition: all .3s;


          &:hover {
            color: #fff;
            background-color: #000;
          }
        }
      }
    }

  }
}








