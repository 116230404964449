/* =========================
Page Header
=============================*/

.breadcrumb-area {

  &.breadcrumb-title-bar {
    .breadcrumb-inner {
      h2 {
        color: #fff;
      }
    }
  }

}



























