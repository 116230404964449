.text-crawler {
    white-space: nowrap;
    position: relative;
    h1 {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #000;
        display: inline-block;
        animation: crawling 100s linear infinite;

        @media #{$sm-layout} {
          animation: crawling 50s linear infinite;
        }
    
      }
    img {
      width: 3ex;
      // width: 15vmax;
    }
    &.light-text {
        h1 {
            -webkit-text-stroke-color: #919191;
        }
    }
  }


  @keyframes crawling {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
  