
#loader-overlay {
    visibility: hidden;    
    opacity: 0;
    width: 100%;
    height: 100vh;
    background: $color-1;
    position: fixed;
    z-index: 1;
    left: 0;
    top:0;
    right: 0;
    animation: hide 2s linear;
}

.wave-loader {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    animation: fill 2s linear;
    .wavify-item {
      height: 150vh;
      svg {
        position: relative;
        left: 0;
        right: 0;
        bottom: 200px;
      }
    }
  }
  
  @keyframes fill {
    0% {
      transform: translateY(100%);
    }
    75% {
      transform: translateY(0%);
    }
  }

  @keyframes hide {
    0% {
      visibility: visible;
      opacity: 1;
      z-index: 999999;
    }
    75% {
      visibility: visible;
      opacity: 1;
      z-index: 999999;
    }
    100% {
      opacity: 0;
      z-index: 1;
      visibility: hidden;
    }
  }
  

  
.wrapper {
  animation: fadein 1s linear;
}
@keyframes fadein {
  0% {
    opacity:0;
  }
  100% {
      opacity:1;
  }
}