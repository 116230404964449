.tooltip-link {
    text-decoration:none;
    position:relative;
}
.tooltip-description {
    display:none;
    margin: 0px;
    z-index: 1000;
    img {
        max-width: 450px;
    }
    &.text {
        h3, p{
            margin-bottom: 0;
        }
        p{
            font-size: 16px;
        }
        color: $black;
        background-color: $white;
        padding: 5px 10px;
    }

}
.tooltip-link:hover .tooltip-description {
    display:block;
    position:fixed;
    overflow:hidden;
    @media #{$lg-layout} {
        display: none;
    }
}