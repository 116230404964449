/**
 * Typography
 */

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden;
  font-size: $bodyFontSize;
  font-weight: $font-weight;
  line-height: $body-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $bodyFont;
  color: $black;
}


a {
  transition: 0.3s;
}

h1,
h2 {
  margin-bottom: 0px;
}


h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading-font;
  line-height: 1.3;
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: $h1;
  font-weight: $font-weight-bold;
}

h2,
.h2 {
  font-size: $h2;
  font-weight: $font-weight-bold;
}

h3,
.h3 {
  font-size: $h3;
}

h4,
.h4 {
  font-size: $h4;
}

h5,
.h5 {
  font-size: $h5;
}

h6,
.h6 {
  font-size: $h6;
}


@media #{$md-layout} {
  body {
    font-size: $bodyFontSize - 2;
  }

  h1,
  .h1 {
    font-size: $h1 - 80;
  }

  h2,
  .h2 {
    font-size: $h2 - 20;
  }
  h3,
  .h3 {
    font-size: $h3 - 2;
  }
}

@media #{$sm-layout} {
  h1,
  .h1 {
    font-size: $h1 - 180;
  }

  h2,
  .h2 {
    font-size: $h2 - 45;
  }
  h3,
  .h3 {
    font-size: $h3 - 2;
  }
}


h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 900;
}

h4,
.h4,
h5,
.h5 {
  font-weight: 600;
}

h6,
.h6 {
  font-weight: 500;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a:visited {
  color: inherit;
}

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid $border-color;
  transition: all 0.4s ease-out 0s;
  color: $black;

  &:focus,
  &:active {
    outline: none;
  }
}

input,
select,
textarea {
  width: 100%;
  font-weight: $font-weight-medium;
  font-size: 16px;
}

input,
select {
  height: 40px;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}