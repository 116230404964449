// Font Family 
$bodyFont: 'Poppins', sans-serif;
$heading-font: 'Oswald', sans-serif;;
$secondary-font: 'Poppins', sans-serif;

$font-1: 'Poppins', sans-serif;
$font-2: 'Poppins', sans-serif;
// Template Font List
$fontList: $font-1, $font-2;


// Template Colors
$gray: #919191;
$white: #FFF;
$black: #000;
$color-1: #EA6650;
$color-2: #023DAE;
$color-3: #FFD7D7;
$colorList: $color-1, $color-2, $color-3;


// Line Height
$body-line-height: 24px;
$placeholder-color: #000;

// Font size
$bodyFontSize: 20px;
$h1: 150px;
$h2: 80px;
$h3: 50px;
$h4: 20px;
$h5: 18px;
$h6: 14px;

// Font weight
$font-weight: 200;
$font-weight-medium: 600;
$font-weight-bold: 900;

// Layouts
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 3000px)';
$device: 'only screen and (max-width: 1900px)';
$laptop-device: 'only screen and (max-width: 1599px)';
$lg-layout: 'only screen and (max-width: 1199px)';
$custom-lg-layout: 'only screen and (max-width: 1090px)';
$md-layout: 'only screen and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 756px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';

body {
  height: auto;
  background-color: $black;
  user-select: none;
  -webkit-user-select: none; 
  -moz-user-select: none;
}

a {
  color: $black;
  &:hover {
    color: $white;
  }
}

img {
  -webkit-user-drag: none; 
  -moz-user-drag: none; 
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: $color-1;
}

@media #{$extra-device} {
  .container {
    max-width: 1400px;
  }
}
