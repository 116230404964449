/*=========================
    Portfolio Grid Metro 
 ==========================*/

.portfolio-grid {
    margin: 0 70px;

    @media #{$md-layout}{
        margin: 0 30px;
    }

    @media #{$sm-layout}{
        margin: 0 20px;
    }

    .portfolio {
        width: 25%;
        padding: 0 20px;
        margin-bottom: 40px;
        
        .thumb {
            position: relative;
            img {
                width: 100%;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $black;
                opacity: 0;
                transition: all .3s;
                -webkit-transition: all .3s;

            }
            &:hover::after {
                opacity: .5;
            }
        }



        @media #{$lg-layout} {
            width: 50%;
        }
        @media #{$md-layout} {
            width: 100%;
            padding: 0 15px;
            margin-bottom: 30px;
        }
        @media #{$sm-layout} {
            width: 100%;
            padding: 0 15px;
            margin-bottom: 30px;
        }

    }

    .grid-width-2{
        width: 50%;
    }
    .resizer {
        width: 25%;
    }

    @media #{$laptop-device} {
        .portfolio {
            width: 50%;
            padding: 0 15px;
            margin-bottom: 30px;
        }
        .grid-width-2 {
            width: 50%;
        }
        .resizer {
            width: 50%;
        }
    }

    @media #{$large-mobile} {
        .portfolio {
            width: 100%;
            padding: 0;
        }
        .grid-width-2{
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }


}







