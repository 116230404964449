  .accordion {
    .accordion-item {
      border-top: 1px solid $gray;
    }
    button {
      position: relative;
      display: block;
      text-align: left;
      width: 100%;
      padding: 25px 0;
      color: $white;
      border: none;
      background: none;
      outline: none;
      h2 {
          width: 75%;
      }
      &:hover, &:focus {
        cursor: pointer;
    }
      .icon {
        display: inline-block;
        position: absolute;
        top: 50px;
        right: 0;
        width: 50px;
        height: 50px;
        &::before {
          display: block;
          position: absolute;
          content: '';
          top: 24px;
          width: 50px;
          height: 2px;
          background: currentColor;
          @media #{$sm-layout} {
            top: 12px;
            width: 25px;
            height: 2px;
          }
        }
        &::after {
          display: block;
          position: absolute;
          content: '';
          left: 24px;
          width: 2px;
          height: 50px;
          background: currentColor;
          @media #{$sm-layout} {
            left: 12px;
            width: 2px;
            height: 25px;
          }
        }
        @media #{$sm-layout} {
            top: 25px;
            width: 25px;
            height: 25px;
        }
      }
    }
    button[aria-expanded='true'] {
      .icon {
        &::after {
            display: none;
        }
      }
      + .accordion-content {
        opacity: 1;
        max-height: 25vh;
        transition: all 200ms linear;
        will-change: opacity, max-height;
      }
    }
    .accordion-content {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity 200ms linear, max-height 200ms linear;
      will-change: opacity, max-height;
      p {
        width: 50%;
        padding: 0 0 50px 0;
        font-size: 16px;
        color: $white;
        @media #{$sm-layout} {
            width: 100%;
        }
      }
    }
  }





  .accordion {
    list-style: none;
  }
  
  .accordion_item {
    border-top: 1px solid #9f9f9f;
  }
  
  .button {
    font-size: 16px;
    background-color: #008593;
    color: #fff;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    cursor: pointer;
    border: none;
  }
  
  .control {
    font-size: 20px;
  }
  
  .answer {
    background-color: #f7f7f7;
    padding: 20px;
  }
  
  /* activate toggle */
  .accordion_item.active .button {
    background-color: #105057;
  }
  .answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }