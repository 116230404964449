/* ==========================
    Mobile Menu Header
==============================*/

.popup-mobile-menu-wrapper {
  .popup-mobile-menu {
    visibility: visible;
    opacity: 1;

    .inner {
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
}


.popup-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s;
  z-index: 99999;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .9);
  }

  .inner {
    background: $black;
    position: relative;
    width: 400px;
    max-width: 75%;
    height: 100%;
    -webkit-transition: all 1s;
    transition: all 1s;
    overflow-y: auto;
    padding-top: 50px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);

    & .mobileheader {
      position: absolute;
      top: 0;
      right: 0;
      padding: 20px;

      button {
        &.mobile-close {
          border: none;
          position: relative;
          cursor: pointer;
          height: 40px;
          width: 40px;
          float: right;

          &::before,
          &::after {
            position: absolute;
            top: 19px;
            left: 8px;
            content: "";
            width: 24px;
            height: 2px;
            background: $white;
            transform-origin: 50% 50%;
            -webkit-transition: .2s ;
            transition: .2s;
          }
          &::before {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
          &::after {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          &:hover {
            &::before,
            &::after {
              -webkit-transform: none;
              -ms-transform: none;
              transform: none;
            }
          }
        }
      }
    }


  }
}


/* =======================
    Mobile Menu Content
==========================*/

.object-custom-menu {
  padding: 0;
  list-style: none;
  margin: 20px 15px;

  li {
    position: relative;
    a {
      padding-top: 15px;
      padding-bottom: 15px;
      display: block;
      position: relative;
      span {
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $white;
      }
      &.navlink-active{
        span {
          -webkit-text-fill-color: $white;
          -webkit-text-stroke-width: 0px;
        }
        &::before{
          width: 20px;
          height: 20px;
          background-image: url("../../img/shapes/hand.svg");
        }
      }
    }

  }

}












