/**
 * Utilities
 */

/* Font Family */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,900|Permanent+Marker|Playfair+Display:400,400i,700,700i,900,900i|Montserrat:300,300i,400,400i,500,500i,600,700,700i,800|Open+Sans:400|Roboto:400,400i,500,500i,700,700i,900|Reenie+Beanie');


.hidden {
  display: none;
}

.fix {
  overflow: hidden;
}

.poss_relative {
  position: relative;
}

.invisible {
  visibility: hidden;
}



/* Define Colors */

.color-1 {
  color: $color-1;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}

.color-gray {
  color: $gray;
}
.color-black {
  color: $black;
}
.color-white {
  color: $white;
}



// Font Family
.primary-font {
  font-family: $bodyFont;
}

.secondary-font {
  font-family: $secondary-font;
}



/* Background Color */

@include config-bg-colors('bg_color--',
        '1' $color-1,
        '2' $color-2,
        '3' $color-3,
        '4' $black,
        );

.bg-as-text {
  background-repeat: no-repeat;
  background-position: right center;

  @media #{$sm-layout} {
    background-size: 554px;
  }

  @media #{$md-layout} {
    background-size: 750px;
  }
}


.bg-as-text2 {
  background-repeat: no-repeat;
  background-position: center center;
}



/* Background Image */

.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



/* This color is default color. It will change depending your body template color class like .template-color-1 to 11  */
.text-color {
  color: $color-1;
}


/*-- Padding Top --*/
@for $i from 1 through 80 {
  .pt--#{5 * $i} {
    padding-top: 5px *$i;
  }
}

@media #{$large-mobile} {
  @for $i from 1 through 10 {
    .pt_sm--#{5 * $i} {
      padding-top: 5px *$i;
    }
  }
}

/*-- Padding Bottom --*/
@for $i from 1 through 70 {
  .pb--#{5 * $i} {
    padding-bottom: 5px *$i;
  }
}

/*-- Padding Left --*/
@for $i from 1 through 90 {
  .pl--#{5 * $i} {
    padding-left: 5px *$i;
  }
}

.pl--5 {
  padding-left: 5px !important;
}


/*-- Padding Right --*/
@for $i from 1 through 50 {
  .pr--#{5 * $i} {
    padding-right: 5px *$i;
  }
}

@media #{$large-mobile} {
  @for $i from 1 through 10 {
    .pr_sm--#{5 * $i} {
      padding-right: 5px *$i;
    }
  }
}


/*-- Padding Left Right --*/

@for $i from 1 through 50 {
  .plr--#{5 * $i} {
    padding: 0 5px *$i;
  }
}


@media #{$lg-layout} {
  @for $i from 1 through 50 {
    .plr_lg--#{5 * $i} {
      padding: 0 5px *$i;
    }
  }
}

@media #{$laptop-device} {
  @for $i from 1 through 50 {
    .plr_lp--#{5 * $i} {
      padding: 0 5px *$i;
    }
  }
}

@media #{$laptop-device} {
  @for $i from 1 through 50 {
    .plr_lp--#{5 * $i} {
      padding: 0 5px *$i;
    }
  }
}


@media #{$md-layout} {
  @for $i from 1 through 50 {
    .plr_md--#{5 * $i} {
      padding: 0 5px *$i;
    }
  }
  .ml_md--30 {
    margin-left: 30px !important;
  }
}

@media #{$sm-layout} {
  @for $i from 1 through 50 {
    .plr_sm--#{5 * $i} {
      padding: 0 5px *$i;
    }
  }
  .ml_sm--30 {
    margin-left: 30px !important;
  }
}


/*-- Margin Top --*/
@for $i from 1 through 40 {
  .mt--#{5 * $i} {
    margin-top: 5px *$i;
  }
}

/*-- Margin Top Minus --*/
@for $i from 1 through 40 {
  .mt--n#{5 * $i} {
    margin-top: -5px *$i;
  }
}


/*-- Margin Bottom --*/
@for $i from 1 through 20 {
  .mb--#{5 * $i} {
    margin-bottom: 5px *$i;
  }
}

.pl--0 {
  padding-left: 0;
}

.pr--0 {
  padding-right: 0;
}

/*-- Margin Right --*/
.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

@for $i from 1 through 20 {
  .mr--#{5 * $i} {
    margin-right: 5px *$i;
  }
}

/*-- Margin Bottom --*/
@for $i from 1 through 35 {
  .ml--#{5 * $i} {
    margin-left: 5px *$i;
  }
}

/*-- Margin Bottom --*/
@for $i from 1 through 70 {
  .ptb--#{5 * $i} {
    padding: 5px *$i 0;
  }
}


/* =================================
    Responsive Spacing Css 
====================================*/

@media #{$laptop-device}{
  @for $i from 1 through 30 {
    .pb_lp--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
    .pr_lp--#{5 * $i} {
      padding-right: 5px *$i;
    }
    .pl_lp--#{5 * $i} {
      padding-left: 5px *$i;
    }
    .pt_lp--#{5 * $i} {
      padding-top: 5px *$i;
    }
    .ptb_lp--#{5 * $i} {
      padding: 5px *$i 0;
    }

  }
}


// Padding Spacing
@media #{$lg-layout} {

  .pr_lg--0 {
    padding-right: 0;
  }
  .pl_lg--0 {
    padding-left: 0;
  }
  @for $i from 1 through 60 {
    .pb_lg--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
    .pt_lg--#{5 * $i} {
      padding-top: 5px *$i;
    }
  }

  @for $i from 1 through 30 {
    .pl_lg--#{5 * $i} {
      padding-left: 5px *$i;
    }
    .pr_lg--#{5 * $i} {
      padding-right: 5px *$i;
    }
    .ptb-lg--#{5 * $i} {
      padding: 5px *$i 0;
    }
  }

}


@media #{$md-layout} {
  .pr_md--0 {
    padding-right: 0;
  }
  .pl_md--0 {
    padding-left: 0;
  }
  @for $i from 1 through 40 {
    .pb_md--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
  }

  @for $i from 1 through 20 {
    .pl_md--#{5 * $i} {
      padding-left: 5px *$i;
    }
    .pr_md--#{5 * $i} {
      padding-right: 5px *$i;
    }
  }

  @for $i from 1 through 20 {

  }

  @for $i from 1 through 60 {
    .pt_md--#{5 * $i} {
      padding-top: 5px *$i;
    }
  }

  @for $i from 1 through 30 {
    .ptb-md--#{5 * $i} {
      padding: 5px *$i 0;
    }
  }
  .space_dec_md--180 {
    margin-top: -180px;
  }
}

@media #{$sm-layout} {
  .pr_sm--0 {
    padding-right: 0;
  }
  .pl_sm--0 {
    padding-left: 0;
  }
  @for $i from 1 through 40 {
    .pb_sm--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
  }


  @for $i from 1 through 20 {
    .pl_sm--#{5 * $i} {
      padding-left: 5px *$i;
    }
  }

  @for $i from 1 through 20 {
    .pr_sm--#{5 * $i} {
      padding-right: 5px *$i;
    }
  }

  @for $i from 1 through 40 {
    .pt_sm--#{5 * $i} {
      padding-top: 5px *$i;
    }
  }

  @for $i from 1 through 30 {
    .ptb-sm--#{5 * $i} {
      padding: 5px *$i 0;
    }
  }


}

@media #{$large-mobile} {
  @for $i from 1 through 20 {
    .pb_mobile--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
    .pl_mobile--#{5 * $i} {
      padding-left: 5px *$i;
    }
    .pr_mobile--#{5 * $i} {
      padding-right: 5px *$i;
    }
    .pt_mobile--#{5 * $i} {
      padding-top: 5px *$i;
    }
    .pb_mobile--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
  }
}

@media #{$laptop-device} {
  @for $i from 0 through 20 {
    .mb_lp--#{5 * $i} {
      margin-bottom: 5px *$i;
    }
    .ml_lp--#{5 * $i} {
      margin-left: 5px *$i;
    }
    .mr_lp--#{5 * $i} {
      margin-right: 5px *$i;
    }
    .mt_lp--#{5 * $i} {
      margin-top: 5px *$i;
    }

    .pb_lp--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
    .pl_lp--#{5 * $i} {
      padding-left: 5px *$i;
    }
    .pr_lp--#{5 * $i} {
      padding-right: 5px *$i;
    }
    .pt_lp--#{5 * $i} {
      padding-top: 5px *$i;
    }
    .pb_lp--#{5 * $i} {
      padding-bottom: 5px *$i;
    }
  }
}


// Margin Spacing

@media #{$lg-layout} {
  @for $i from 0 through 20 {
    .mb_lg--#{5 * $i} {
      margin-bottom: 5px *$i;
    }
    .ml_lg--#{5 * $i} {
      margin-left: 5px *$i;
    }
    .mr_lg--#{5 * $i} {
      margin-right: 5px *$i;
    }
    .mt_lg--#{5 * $i} {
      margin-top: 5px *$i;
    }
  }
  .ml_lg--0 {
    margin-left: 0;
  }

}

@media #{$md-layout} {
  .mb_md--0{
    margin-bottom: 0;
  }

  .mt_md--0{
    margin-top: 0;
  }
  @for $i from 0 through 20 {
    .mb_md--#{5 * $i} {
      margin-bottom: 5px *$i;
    }
    .ml_md--#{5 * $i} {
      margin-left: 5px *$i;
    }
    .mr_md--#{5 * $i} {
      margin-right: 5px *$i;
    }
    .mt_md--#{5 * $i} {
      margin-top: 5px *$i;
    }
  }

  .ml_md--0 {
    margin-left: 0;
  }

}

@media #{$sm-layout} {
  .mb_sm--0{
    margin-bottom: 0;
  }

  .mt_sm--0{
    margin-top: 0;
  }
  @for $i from 0 through 20 {
    .mb_sm--#{5 * $i} {
      margin-bottom: 5px *$i;
    }
    .ml_sm--#{5 * $i} {
      margin-left: 5px *$i;
    }
    .mr_sm--#{5 * $i} {
      margin-right: 5px *$i;
    }
    .mt_sm--#{5 * $i} {
      margin-top: 5px *$i;
    }
  }
  .ml_sm--0 {
    margin-left: 0;
  }
}


@media #{$large-mobile} {
  .mb_mobile--0{
    margin-bottom: 0;
  }

  .mt_mobile--0{
    margin-top: 0;
  }
  @for $i from 1 through 20 {
    .mb_mobile--#{5 * $i} {
      margin-bottom: 5px *$i;
    }
    .ml_mobile--#{5 * $i} {
      margin-left: 5px *$i;
    }
    .mr_mobile--#{5 * $i} {
      margin-right: 5px *$i;
    }
    .mt_mobile--#{5 * $i} {
      margin-top: 5px *$i;
    }

  }

}


/*-----------------------
Padding Top Bottom 
----------------------------*/


/* Section Padding Top Bottom */

.section-ptb-xl {
  padding: 150px 0;

  @media #{$md-layout} {
    padding: 80px 0;
  }

  @media #{$sm-layout} {
    padding: 70px 0;
  }

  @media #{$large-mobile} {
    padding: 60px 0;
  }
}

.section-ptb-120 {
  padding: 120px 0;

  @media #{$md-layout} {
    padding: 80px 0;
  }

  @media #{$sm-layout} {
    padding: 70px 0;
  }

  @media #{$large-mobile} {
    padding: 60px 0;
  }
}

.section-ptb-110 {
  padding: 110px 0;

  @media #{$md-layout} {
    padding: 80px 0;
  }

  @media #{$sm-layout} {
    padding: 70px 0;
  }

  @media #{$large-mobile} {
    padding: 60px 0;
  }
}

.section-ptb-100 {
  padding: 100px 0;

  @media #{$md-layout} {
    padding: 80px 0;
  }

  @media #{$sm-layout} {
    padding: 70px 0;
  }

  @media #{$large-mobile} {
    padding: 60px 0;
  }
}

.section-ptb-150 {
  padding: 150px 0;

  @media #{$md-layout} {
    padding: 80px 0;
  }

  @media #{$sm-layout} {
    padding: 70px 0;
  }

  @media #{$large-mobile} {
    padding: 60px 0;
  }
}


/* Section Padding Top */

.section-pt-xl {
  padding-top: 150px;

  @media #{$md-layout} {
    padding-top: 80px;
  }

  @media #{$sm-layout} {
    padding-top: 70px;
  }

  @media #{$large-mobile} {
    padding-top: 60px;
  }
}

.section-pt-110 {
  padding-top: 110px;

  @media #{$md-layout} {
    padding-top: 80px;
  }

  @media #{$sm-layout} {
    padding-top: 70px;
  }

  @media #{$large-mobile} {
    padding-top: 60px;
  }
}

.section-pt-120 {
  padding-top: 120px;

  @media #{$md-layout} {
    padding-top: 80px;
  }

  @media #{$sm-layout} {
    padding-top: 70px;
  }

  @media #{$large-mobile} {
    padding-top: 60px;
  }
}

.section-pt-100 {
  padding-top: 100px;

  @media #{$md-layout} {
    padding-top: 80px;
  }

  @media #{$sm-layout} {
    padding-top: 70px;
  }

  @media #{$large-mobile} {
    padding-top: 60px;
  }
}

.section-pt-150 {
  padding-top: 150px;

  @media #{$md-layout} {
    padding-top: 80px;
  }

  @media #{$sm-layout} {
    padding-top: 70px;
  }

  @media #{$large-mobile} {
    padding-top: 60px;
  }
}


/* Section Padding Bottom */
.section-pb-xl {
  padding-bottom: 150px;

  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 70px;
  }

  @media #{$large-mobile} {
    padding-bottom: 60px;
  }
}

.section-pb-110 {
  padding-bottom: 110px;

  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 70px;
  }

  @media #{$large-mobile} {
    padding-bottom: 60px;
  }
}


.section-pb-120 {
  padding-bottom: 120px;

  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 70px;
  }

  @media #{$large-mobile} {
    padding-bottom: 60px;
  }
}

.section-pb-100 {
  padding-bottom: 100px;

  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 70px;
  }

  @media #{$large-mobile} {
    padding-bottom: 60px;
  }
}

.section-pb-150 {
  padding-bottom: 150px;

  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 70px;
  }

  @media #{$large-mobile} {
    padding-bottom: 60px;
  }
}


/* Text specialized */
.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  font-style: underline;
}

/* Font specialized */
.body-font {
  font-family: $bodyFont;
}

.heading-font {
  font-family: $heading-font;
}

/* Height and width */
.fullscreen {
  min-height: 100vh;
  width: 100%;

  &.fullscreen-md--fixed {

    @media #{$md-layout} {
      min-height: auto;
      width: 100%;
      padding: 150px 0;
    }

    @media #{$sm-layout} {
      min-height: auto;
      width: 100%;
      padding: 150px 0;
    }

  }
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.33%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.max-width--600 {
  max-width: 600px;
  width: 100%;

  @media #{$md-layout} {
    max-width: 100%;
  }

  @media #{$sm-layout} {
    max-width: 100%;
  }
}


.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.h-33 {
  height: 33.33%;
}

.h-10 {
  height: 10%;
}

/*--------------------------
	Overlay styles 
---------------------------*/

[data-overlay],
[data-black-overlay],
[data-heading-overlay],
[data-light-overlay],
[data-white-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-light-overlay] > *,
[data-white-overlay] > div,
[data-heading-overlay] > div,
[data-light-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-heading-overlay]:before,
[data-light-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-overlay]:before {
  background-color: $color-1;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-light-overlay]:before {
  background-color: #5e0891;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-heading-overlay]:before {
  background-color: #222222;
}

@for $i from 1 through 10 {

  [data-overlay="#{$i}"]:before,
  [data-black-overlay="#{$i}"]:before,
  [data-white-overlay="#{$i}"]:before,
  [data-light-overlay="#{$i}"]:before,
  [data-heading-overlay="#{$i}"]:before {
    opacity: #{$i * 0.10};
  }
}

.bg-theme,
.bg-dark,
.bg-dark-light,
.bg-secondary {
  color: #ffffff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #ffffff;
  }
}

/*------------------------------
    ScrollTo Up 
--------------------------------*/
#scrollUp {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 25px;
  line-height: 60px;

  background-color: #ffffff;
  // color: $heading-color;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  z-index: 9811 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);

  @media #{$small-mobile} {
    display: none !important;
  }

  & i {
    display: block;
    line-height: 55px;
    font-size: 25px;
  }

  &:hover {
    & i {
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
    }
  }
}


// Contact Form

.form-message {
  margin-bottom: 0;
  text-align: center;

  &.error {
    margin-top: 20px;
    color: #f80707;
  }

  &.success {
    margin-top: 20px;
    color: #0d8d2d;
  }
}

.bk-parallax {
  position: relative;
  z-index: 0;
}

.bk-parallax > .bk-parallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


/*----------------------
    Social Color
------------------------*/