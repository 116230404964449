/*========================
 * Header
=========================================*/

.page_nav {
  ul {
    &.mainmenu {
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
    }
  }
}


/* ============================
    Header Default Area 
================================*/


/* Maninmenu Area */

.mainmenu-wrapper {
  .page_nav {
    ul {
      &.mainmenu {
        justify-content: center;
        padding: 0;
        margin: 0;

        li {

          &.label-1 {
            display: inline-block;
            padding: 41px 9px 41px 0;

            a {
              position: relative;
              overflow: hidden;
              z-index: 1;
              font-weight: $font-weight-medium;
              font-size: 16px;
              padding: 41px 15px;
              -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
              transition: all .3s cubic-bezier(.645, .045, .355, 1);

              span {
                position: relative;
                overflow: hidden;

                &::after {
                  content: "";
                  width: 0;
                  height: 2px;
                  bottom: 0;
                  position: absolute;
                  left: auto;
                  right: 0;
                  z-index: -1;
                  -webkit-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
                  transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
                  background: currentColor;
                }
              }

              &.navlink-active{
                color: $color-1 !important;
                span {
                  &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                  }
                }
              }
            }

            &:hover {
              a {
                color: $color-1 !important;
                span {
                  &::after {
                    width: 100%;
                    left: 0;
                    right: auto;
                  }
                }
              }
            }

          }
        }
      }
    }
  }
}

.lg-not-transparent {
  // Responsive
  @media #{$lg-layout}{
    position: fixed !important;
    background-color: $white !important;
    padding-top: 0px !important;
  }
  @media #{$md-layout}{
    position: fixed !important;
    background-color: $white !important;
    padding-top: 0px !important;
  }
  @media #{$sm-layout}{
    position: fixed !important;
    background-color: $white !important;
    padding-top: 10px !important;
  }
}

header.br_header.header-default.vertical-slide-portfolio {
  background: #FFF;
}


.header-default {
  position: relative;
  z-index: 9;

  &.header-fixed-width {

    .container-fluid {
      padding-left: 100px;
      padding-right: 100px;

      @media #{$lg-layout} {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media #{$md-layout} {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media #{$sm-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }

    }
  }

  // Header Transparent
  &.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    &.position-from--top {
      padding-top: 24px;

      &.sticky {
        padding-top: 0;
      }
    }
  }

  // Header Wrapper
  .header__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    @media #{$md-layout} {
      position: relative;
      z-index: 9;
    }

    .header-left {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 20%;
      -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      @media #{$md-layout, $sm-layout, $large-mobile} {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
      }

      &.flex-80{
        flex-basis: 80%;
      }

      .logo {
        a {
          img {
            padding-top: 15px;
            padding-bottom: 15px;
            width: 200px;
            height: auto;
            max-width: 100%;
          }
        }
      }

    }

    // Mainmenu
    .header-flex-right {
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }


    .mainmenu-wrapper {

      &.have-not-flex {
        flex-grow: inherit;

        .page_nav {
          ul {
            &.mainmenu {
              justify-content: flex-end;
            }
          }
        }
      }
    }

    // Header Right
    .header-right {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 80%;
      -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin: 0 -12px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;

      // Not Flex Header
      &.have-not-flex {
        flex-basis: inherit;

      }

      &.flex-20 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
      }

      @media #{$md-layout, $sm-layout, $large-mobile} {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
      }

    }
  }

  .header-right {
    margin: 0 -12px;

    > div {
      margin: 0 12px;
    }

  }


  // Header Color Light
  &.light-logo {
    .mainmenu-wrapper {
      .page_nav {
        ul {
          &.mainmenu {
            li {
              a {
                color: #ffffff;

                &::after {
                  background: #ffffff;
                }
              }
             
            }
          }
        }
      }
    }
  }


}




/* ============================
    Sticky Header Css 
================================*/

.is-sticky {
  &.sticky {
    background-color: rgba(0, 0, 0, 0.9);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);
    -webkit-animation: headerSlideDown .95s ease forwards;
    animation: headerSlideDown .95s ease forwards;
  }
}

.is-sticky.sticky .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 {
  padding-top: 31px;
  padding-bottom: 31px;
}

.is-sticky.sticky .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 > a {
  padding-top: 31px;
  padding-bottom: 31px;
}

.header-default.sticky .header__wrapper .header-left .logo a img {
  width: 150px;
}

.header-default.header-architecture.sticky .header__wrapper .header-left .logo a img {
  width: auto;
}

.header-default.header-portfolio-slide.sticky .header__wrapper {
  padding: 16px 0;
}


/*=================================
    Header Fixed 
=====================================*/
header.br_header.header-default.header-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}