.square-btn {
    padding: 15px 25px;
    border-width: 2px;
    border-style: solid;
    font-size: 16px;
    font-weight: 600;
    border-color: #000;
    -webkit-transition: all .3s;
    transition: all .3s;

      &:hover {
        color: #fff;
        background-color: #000;
      }
    }
