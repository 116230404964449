/* =========================
    Image Slide
===========================*/
  .project-page{
    height: 100vh;
    @media #{$md-layout} {
      height: auto;
    }
    .swiper-wrapper { 
      @media #{$md-layout} {
        transform: translate3d(0px, 0px, 0px) !important;
        display: block !important;
      }
    }
  }

  .image-slide{
      width: auto !important;
      box-sizing: border-box;
        img{
            width: auto;
            padding: 15vh 0;
            height: 100vh;
            margin: 0 10px;
            @media #{$md-layout} {
              width: 100%;
              height: auto;
              padding: 0 15px 20px 20px;
              margin: 0;
            }
        } 
  }

  .scroll-btn {
    position: absolute; 
    margin-left: 50px;
    bottom: 50px;
      span {
        color: #919191;
        -webkit-transition: .3s;
        transition: .3s;
        cursor: pointer;
        &.btn-arrow {
          transition: .5s !important;
          display: inline-block;
          margin-bottom: 6px;
          position: relative;
          width: 50px;
          height: 2px;
          background: currentColor;
          margin-left: 15px;
          font-size: 14px;
          &::before {
            content: "";
            position: absolute;
            right: 0px;
            width: 15px;
            height: 15px;
            border: 2px solid currentColor;
            border-left: 0;
            border-bottom: 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            top: -6px;
          }
        }
      }
      &:hover {
        span {
          color: #FFF;
          &.btn-arrow {
            transform: translateX(50%);
            -webkit-transform: translateX(50%);
          }
        }
      }
      @media #{$md-layout} {
        display: none;
      }
  }
