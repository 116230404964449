
#cookie-consent {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.75);
    &.hidden {
        display: none;
    }
}

.cookies-popup {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 450px;
    height: 400px;
    border-top-left-radius: 100%;
    background-color: $color-1;
   
   .cookies-content {
       position: absolute;
       bottom: 0;
       right: 0;
       padding: 50px 15px;
       text-align: right;
       @media #{$large-mobile} {
        padding: 100px 15px;
      }
        h3 {
            span {
                text-decoration: line-through $white;
                &::after {
                    content: 'HAVE';
                    position: absolute;
                    transform: translate(-110%, 60%);
                    color: $white;
                    text-decoration: none;
                    font-style: italic;
                }
            }
        }

        .cookies-options {
            margin-top: 50px;
            @media #{$large-mobile} {
                margin-top: 20px;
            }
            .square-btn {
                margin-right: 25px;
            }
            .more-cookies {
                font-size: 16px;
                font-weight: 600;
            }
        }
   }


}