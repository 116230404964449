/* ------------------------
    Social Area 
----------------------------*/
.social-icon {
  padding: 0;

  li {
    display: inline-block;

    a {
      font-size: 18px;
      padding: 5px 15px;
      color: #FFF;
      -webkit-transition: .3s;
      transition: .3s;
    }
  }

  // Border Icon
  &.icon-rounded {
    li {
      margin-right: 15px;
      a {
        display: block;
        text-align: center;
        height: 40px;
        width: 40px;
        background: #000;
        color: $color-1;
        border-radius: 50%;
        line-height: 40px;
        position: relative;
        padding: 0;
      }
      &:hover{
        color: #FFF;
      }
    }

  }

}

