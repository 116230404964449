.hero-content {

  @media #{$sm-layout} {
    padding-bottom: 25px;
    padding-top: 175px;
  }

  h2{
    color: #fff;
  }

  p{
    max-width: 400px;
    position: absolute;
    right: 0;
    color: $white;
    @media #{$laptop-device} {
      right: 0;
    }
  }

  .tooltip-link {
    color: $white;
  }

  .tooltip-link:hover{
    color: $color-2
  }
}
