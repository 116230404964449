.tpc-text{
    h2 {
        color: $white;
    }

    p {
        font-size: 16px;
        color: $gray;
        span {
            font-weight: 600;
            color: $white;
        }
    }

}