/* =========================
Project Header
=============================*/
button {
  &.page-close {
    border: none;
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    z-index: 100;
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 24px;
      height: 2px;
      background: $black;
      transform-origin: 50% 50%;
      -webkit-transition: .2s ;
      transition: .2s;
      background-color: #919191;
    }
    &::before {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    &::after {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:hover {
      &::before,
      &::after {
        background-color: #FFF;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
    }
  }
}

.project-header {
  margin: 50% 50px;
      .project-title {
        color: $white;
      }
      .project-description{
          font-size: 16px;
          max-width: 600px;
          color: #919191;
          @media #{$laptop-device} {
            font-size: 14px;
          }
          @media #{$md-layout} {
            font-size: 16px;
            margin: 0;
            max-width: 100%;
          }
      }  
  @media #{$md-layout} {
    margin: 50px 25px;
  }
}